// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
import { memo } from 'react';
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Stack,
    Button,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Delete as RemoveIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './../../../render'
import Element from './../../../element'

// const MemoRender = memo(Render)
// const MemoElement = memo(Element)
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {layout, formData, handleAdd, handleEdit, handleDelete} = props

    // const [dataGridColumns, setDataGridColumns] = useState([])
    const [dataGridRows, setDataGridRows] = useState([])
    // const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 

    const [expanded, setExpanded] = React.useState('');    
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    // const [renders, setRenders] = useState([])

    useEffect(() => {
        if (formData == undefined)
            return

        let rows = []
        for (const entity of props.formData) {
            // if (entity.data)
            //     rows.push({id: entity.id, typeId: entity.typeId, ...entity.data})
            // else
                rows.push(entity)
        }

        setDataGridRows(rows)

    }, [formData])
  
    const handleOnChangeRender = (id, formState, typeId, entityData, callback) => {
        props.state.onRendersChange({id: id, valid: formState.valid, dirty: formState.dirty, callback: callback})
    }    

    return (
        <React.Fragment>
            {dataGridRows.map(function (entity, index)  {                
                return (
                    <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ArrowDownwardIcon />}
                            aria-controls={`${entity.id}-content`}
                            id={entity.id}
                        >
                            {layout.build.summary.content.map((element, layoutIndex) => (
                                <Element
                                    element={element}
                                    parentType={undefined}

                                    entity={entity}
                                />
                            ))}                            
                        </AccordionSummary>
                        <AccordionDetails>
                            <Render 
                                entity={entity}                                
                                
                                onChange={
                                    (formState, typeId, entityData, callback) => {handleOnChangeRender(entity.id, formState, typeId, entityData, callback)}
                                }
                            />
                        </AccordionDetails>
                  </Accordion>
                )
            })}  
        </React.Fragment>
    )
}