// --------------------------------------------------------- REACT ------
import * as React from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Button,
    ButtonGroup,
    FormControl,    
    TextField
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Delete as RemoveIcon, 
    CloudDownload as DownloadIcon,
    CloudUpload as UploadIcon    
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
export default (props) => {
    const type = props.type    
    const fieldId = props.fieldId
    const fieldData = props.data?.[fieldId]
    const meta = props.meta
    const uploadProgress = props.uploadProgress
    const formState = props.formState
    const onDownloadFile = props.onDownloadFile
    const onRemoveFile = props.onRemoveFile

    console.log (fieldData)

    return (
        <React.Fragment>       
            <FormControl                 
                fullWidth={true}
                hiddenLabel={true}
            >                
                <ButtonGroup variant="contained"
                    fullWidth
                    disableElevation
                >                    
                    <TextField
                        label={type.fields[fieldId].label}                    
                        value={((fieldData?.[0] && Array.isArray(meta)) ? (meta.find((e) => e.filename == fieldData?.[0])?.originalname) : "")}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                    ></TextField>

                    {((fieldData || []).length == 0)
                    &&             
                        <label htmlFor={fieldId +"-file"}>
                            <LoadingButton 
                                component="span"
                                color={'success'}                            
                                sx={{
                                    width: "30px",
                                    height: "100%",
                                }}           

                                loading={(uploadProgress > 0 && uploadProgress < 100)}                                                
                                disabled={(formState.locked || formState.disabled)}
                            >
                                <UploadIcon />
                            </LoadingButton>
                        </label>
                    }
                    {((fieldData || []).length > 0)
                    &&
                        <React.Fragment>
                            <Button                         
                                color={'primary'}                             
                                sx={{
                                    width: "30px"
                                }}   
                                
                                disabled={(formState.locked || formState.disabled || meta.fileNotFound || (uploadProgress > 0 && uploadProgress < 100))}
                                onClick={() => {onDownloadFile (null)}}
                            >
                                <DownloadIcon />
                            </Button>
                            <Button                         
                                color={'error'}                             
                                sx={{
                                    width: "30px"
                                }}   
                                
                                disabled={(formState.locked || formState.disabled || (uploadProgress > 0 && uploadProgress < 100))}
                                onClick={() => {onRemoveFile (fieldData?.[0])}}
                            >
                                <RemoveIcon />
                            </Button>
                        </React.Fragment>
                    }               
                </ButtonGroup>
            </FormControl>
        </React.Fragment>
    )    
}