// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Stack,
    Button,
    IconButton
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Delete as RemoveIcon,
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {layout, formData, handleAdd, handleEdit, handleDelete} = props

    const [dataGridColumns, setDataGridColumns] = useState([])
    const [dataGridRows, setDataGridRows] = useState([])
    const [dataGridSelectionModel, setDataGridSelectionModel] = useState([]) 

    useEffect(() => {
        if (formData == undefined)
            return

        let columns = []
        for (const column of layout.build.columns) {
            switch (column.type) {
                case "data": {
                    columns.push({
                        headerName: column.headerName || "",
                        field: column.field,
                        flex: column.flex,
                        renderCell: (params) => {
                            return (params.row[column.field])
                        }
                    })
                    break
                }
                
                case "buttons": {
                    columns.push({
                        headerName: "",                        
                        flex: 0.3,
                        sortable: false,
                        renderCell: (params) => {
                            const onClickEdit = () => {
                                handleEdit(params.row.id)
                            }

                            const onClickDelete = () => {
                                handleDelete(params.row.id)
                            }

                            return (
                                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                   
                                    <IconButton aria-label="delete" size="small" onClick={onClickEdit}>
                                        <EditIcon fontSize="inherit"/>
                                    </IconButton>
                                        
                                    <IconButton aria-label="delete" size="small" onClick={onClickDelete}>
                                        <RemoveIcon fontSize="inherit"/>
                                    </IconButton>
                                </Stack>
                            )
                        }
                    })
                    break
                }
            }           
        }

        let rows = []
        for (const entity of props.formData) {
            if (entity.data)
                rows.push({id: entity.id, typeId: entity.typeId, ...entity.data})
            else
                rows.push(entity)
        }

        setDataGridColumns(columns)
        setDataGridRows(rows)

    }, [formData])

    // function getRowId(row) {
    //     return row.id
    // }

    const handleDataGridOnRowSelectionModelChange = (selection) => {
        setDataGridSelectionModel(selection)            
    }

    return (
        <React.Fragment>
            <DataGrid            
                autoHeight                  
                disableColumnMenu
                columns={dataGridColumns} 
                rows={dataGridRows}
                onRowSelectionModelChange={handleDataGridOnRowSelectionModelChange}
                rowsPerPageOptions={[100]} 
                selectionModel={dataGridSelectionModel}      
                // getRowId={getRowId}
                getRowId={(row) => row.id} 
            />

            <Stack spacing={2} direction="row" justifyContent="start" sx={{ pb: '20px' }}>                
                {Array.isArray(layout.build.buttons) &&
                    <React.Fragment>
                        {layout.build.buttons.map(function (button, i)  {
                            return (
                                <Button onClick={() => {handleAdd(button.typeId)}}>{button.label}</Button>        
                            )
                        })}  
                    </React.Fragment>
                }                
            </Stack>            
        </React.Fragment>
    )
}