// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    DialogContentText
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// import LoadingButton from '@mui/lab/LoadingButton'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {   } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { API_URL_SIMPLECRM } from './../../../../../../components/common'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
import {
    SimpleUIAuthState,
    SimpleUIDialogConfirm,

    SimpleUICommonReactSwitch,
    SimpleUICommonReactCase,
    SimpleUICommonReactInitializing,
} from './../../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from '../../../render'
import Dialogg from '../../../dialog'

import ListTable from './table'
import ListDatagrid from './datagrid'
import ListAccordion from './accordion'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    const {fieldId, data: entityData} = props

    const [formData, setFormData] = useState(undefined)
    const {user, accessToken} = SimpleUIAuthState()    
    
    const [dialogCreate, setDialogCreate] = useState(false)
    const [dialogEdit, setDialogEdit] = useState(false)

    const [layout, setLayout] = useState()

    const [dialogStateDelete, setDialogDelete] = useState({ open: false, title: "", children: "", button1Text: "No", button2Text: "Yes", onConfirm: null })


    // const Buttons = (props) => {
        

    //     console.log (formData)
    //     const click = () => {
    //         console.log (props)

    //     }
    //     return (
    //         <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>                    
    //             <IconButton aria-label="edit" size="small">
    //                 <EditIcon fontSize="inherit"/>
    //             </IconButton>
                    
    //             <IconButton aria-label="delete" size="small" onClick={click}>
    //                 <RemoveIcon fontSize="inherit"/>
    //             </IconButton>
    //         </Stack>
    //     )

    // }


    // console.log (props)

    useEffect(() => {        
        const asyncFunction = async (data) => {            
            const entities = []
            for (const iterator of (data || [])) {
                // console.log (iterator)
                if (Object.prototype.toString.call(iterator) !== "[object String]") {   // Only Child entitites.
                    if (props.type.fields[props.fieldId].config.allowedTypes.find(typeId => typeId === iterator.typeId))
                        entities.push(structuredClone(iterator))
                }
            }

            for (const iterator of props.type.fields[props.fieldId].config.allowedTypes) {
                if (props.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes])
                    entities.push(...(await getEntitiesByRef(iterator, `${props.type.fields[props.fieldId].config.refs[props.type.fields[props.fieldId].config.allowedTypes]}:${props.entityId}`)))
            }

            setFormData(entities)
        }                   

        asyncFunction(entityData[fieldId])
    }, [])


    const getEntitiesByRef = async (entityTypeId, ref) => {
        let output = []    
        try {
            let getFetch = await fetch(`${API_URL_SIMPLECRM.ENTITIES}?typeId=${entityTypeId}&ref=${ref}` , {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer "+ accessToken
                }            
            })           

            if (!getFetch.ok)
                throw new Error((await getFetch.json()).error.code)

            output = await getFetch.json()

            // console.log (formData)

            // const index = output.findIndex(i => i.id === type.id)
            // if (index > -1)
            //     output.splice(index, 1)

        } catch (error) {    
            // console.log (error)        
            // onError(error)
        }  

        // console.log (output)

        return output
    }

    useEffect(() => {
    }, [layout])

    useEffect(() => {
        if (formData == undefined)
            return

        if (props.type?.layoutsD[`field_list_${props.fieldId}`]) {
            setLayout(props.type?.layoutsD[`field_list_${props.fieldId}`].content)        
        } else {
            setLayout({
                build: {
                    type: "datagrid",
                    props: {
                        columns: [
                            {
                                type: "data",
                                field: "id",
                                headerName: "ID",
                                flex: 0.5
                            },
                            {
                                type: "data",
                                field: "typeId",
                                headerName: "TYPEID",
                                flex: 0.5
                            }
                        ]
                    }
                }
            })
        }

        handleOnChange(formData) 
    }, [formData])

    const handleOnChange = (value) => {
        if (props.onChange)
            props.onChange({target: {name: props.fieldId, value: value}})
    }
   
    const handleAdd = (typeId) => {
        setDialogEdit({
            open: true,            
            children: (<Render typeId={typeId} entity={null} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
        })
    }

    const handleEdit = (entityId) => {
        if (formData.find(i => i === entityId)) {
            setDialogEdit({
                open: true,                
                children: (<Render entityId={entityId} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
            })
        } else if (formData.find(e => e.id === entityId)) {            
            setDialogEdit({
                open: true,                
                children: (<Render entity={formData.find((e) => e.id === entityId)} createCallback={createCallback} updateCallback={updateCallback} returnCallback={returnCallback} setDialogState={setDialogEdit}/>)
            })
        }
    }
    
    const createCallback = (type, entity) => {
        const updatedFormData = (formData) ? structuredClone(formData) : []
        updatedFormData.push(entity)
        setFormData(structuredClone(updatedFormData))
    }

    const updateCallback = (entity, type) => {
        const updatedFormData = (formData) ? structuredClone(formData) : []
        updatedFormData[formData.findIndex(e => e.id === entity.id)] = entity
        setFormData(structuredClone(updatedFormData))
    }

    const handleDelete = (id) => {
        setDialogDelete({
            open: true,
            title: "Delete entity",
            children: (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this item?
                </DialogContentText>),
            button1Text: "No",
            button2Text: "Yes",
            onConfirm: async () => {

                // console.log()
// 
                const newdata = structuredClone(formData)
                

                newdata.splice(newdata.findIndex((e) => e.id === id), 1)

                setFormData(structuredClone(newdata))

                // try {
                //     let entityDelete = await fetch(API_URL_SIMPLECRM.ENTITIES + id, {
                //         method: 'DELETE', 
                //         headers: {
                //             'Content-Type': 'application/json',
                //             'Authorization': "Bearer "+ accessToken
                //         }
                //     })  
        
                //     if (!entityDelete.ok)
                //         throw new Error((await entityDelete.json()).error.code)
        
                //     updateCallback()
                // } catch (error) {
                //     console.log (error)
                //     // handleError(error)
                // }                
            },
            onDecline: () => {}
        })
    }

    const returnCallback = () => {
        setDialogCreate(false)
        setDialogEdit(false)
    }

    if (!layout) 
        return (<SimpleUICommonReactInitializing></SimpleUICommonReactInitializing>)

    switch (layout.build.type) {
        case "table": {
            return (<ListTable layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete} state={props.state}/>)
        }

        case "accordion": {
            return (<ListAccordion layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete} state={props.state}/>)
        }

        case "datagrid": {
            return (
                <React.Fragment>
                    <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>

                    <Dialogg fullWidth={true} maxWidth={'md'} state={dialogEdit} setState={setDialogEdit}/>
                    <ListDatagrid layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}/>
                </React.Fragment>            
            )
        }

        default: {
            return (<React.Fragment></React.Fragment>)            
        }
    }

    // return (
    //     <React.Fragment>
    //         <SimpleUIDialogConfirm dialogState={dialogStateDelete} setDialogState={setDialogDelete}/>

    //         <Dialogg fullWidth={true} maxWidth={'md'} dialogState={dialogEdit} setDialogState={setDialogEdit}></Dialogg>

    //         <SimpleUICommonReactSwitch condition={layout.content.type}>
    //             <SimpleUICommonReactCase value={"datagrid"}>
    //                 <Default layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></Default>
    //             </SimpleUICommonReactCase>
    //             <SimpleUICommonReactCase value={"accordion"}>
    //                 <ListAccordion layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></ListAccordion>
    //             </SimpleUICommonReactCase>
    //             <SimpleUICommonReactCase value={"table"}>
    //                 <ListTable layout={layout} formData={formData} handleAdd={handleAdd} handleEdit={handleEdit} handleDelete={handleDelete}></ListTable>
    //             </SimpleUICommonReactCase>
    //         </SimpleUICommonReactSwitch>
    //     </React.Fragment>
    // )
}