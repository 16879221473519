// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState } from 'react'
import { memo } from 'react';

// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Box,
    Button,
    IconButton
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Message as EditIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Modal from './modal'

import String from './types/string'
import StringFormat from './types/stringFormat'
import Boolean from './types/boolean'
import Select from './types/select/'
import TextField from './types/textfield/'
import Upload from './types/upload/'
import List from './types/list/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
const Field = (props) => {
    switch (props.type.fields[props.fieldId].type) {
        case "stringformat": {
            return (<StringFormat {...props}/>)
        }
        
        case "textfield": {
            return (<TextField {...props}/>)
        }

        // case "boolean":
        //     return (<Boolean {...props}/>)
            
        case "select": {
            return (<Select {...props}/>)
        }
            
        // case "textfield":
        //     return (<TextField {...props}/>)
        
        case "upload": {
            return (<Upload {...props}/>)
        }

        case "list": {
            return (<List {...props}></List>)
        }

        default: {
            return (<React.Fragment></React.Fragment>)
        }
    }
}

export default (props) => {
    if (props.modal) {
        const [modalFieldState, setModalFieldState] = useState({open: false})

        const handleOpenModal = () => {
            setModalFieldState({
                open: true, 
                fullWidth: true,           
                children: (
                    <Box sx={{
                            p:2
                        }}>
                        <Field {...props}/>
                    </Box>
                )
            })
        }

        return (            
            <React.Fragment>
                <Modal 
                    state={modalFieldState} 
                    setState={setModalFieldState}                    
                />
                <IconButton aria-label="delete" size="small" onClick={handleOpenModal}>
                    <EditIcon fontSize="inherit" />
                </IconButton>                
            </React.Fragment>
        )   
    } else {
        return (
            <Field {...props}/>
        )
    }
}