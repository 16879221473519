// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Dialog,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {   
    const { state, setState } = props 
    const [ children, setChildren ] = useState(null)

    useEffect(() => {
        // Only set children once.
        if (state.children && children === null)
            setChildren(React.cloneElement(state.children))
                
    },[state])    

    const handleClose = (event, reason) => {
        setState({ ...state, open: false })
    }
    
    return (
        <Dialog 
            open={state.open}

            fullWidth
            keepMounted={true}            
            
            onClose={handleClose}            
        >                        
            {children}
        </Dialog>
    )
}