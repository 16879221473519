// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    Stack,
    Button,
    IconButton,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
    
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
import {
    Edit as EditIcon,
    Delete as RemoveIcon,
    ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
import { DataGrid } from '@mui/x-data-grid'
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Render from './../../../render'
import Element from './../../../element'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------
export default (props) => {
    // const internalId = crypto.randomUUID()
    // const internalId = "table"
    const {layout, formData, handleAdd, handleEdit, handleDelete} = props
    // const [renders, setRenders] = useState([])

    const [dataGridRows, setDataGridRows] = useState([])


    useEffect(() => {
        if (formData == undefined)
            return

        let rows = []
    for (const entity of props.formData) {
        // if (entity.data)
        //     rows.push({id: entity.id, typeId: entity.typeId, ...entity.data})
        // else
            rows.push(entity)
    }

    setDataGridRows(rows)


    }, [formData])


    const handleOnChangeRender = (id, formState, typeId, entityData, callback) => {
        props.state.onRendersChange({id: id, valid: formState.valid, dirty: formState.dirty, callback: callback})
    }    

    if (layout.build.columns) {
        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {layout.build.columns.map(function (column, index) {
                                    if (column.headerName !== null) {
                                        return (
                                            <TableCell>{column.headerName}</TableCell>
                                        )
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formData.map(function (entity, index)  {
                                return (
                                    <TableRow                            
                                        key={`${index}`}                                    
                                    >
                                        {layout.build.columns.map(function (column, index)  {
                                            return (
                                                <TableCell component="th" scope="row">
                                                    {column.content.map(function (element, index)  {
                                                        return (
                                                            <Element
                                                                element={element}
                                                                parentType={"tablecell"}
                                                                entity={entity}
                                                            />
                                                        )
                                                    })}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table"> 
                        <TableBody>
                            {dataGridRows.map(function (entity, index)  {
                                return (
                                    <TableRow                            
                                        key={`${index}`}                                    
                                    >
                                        <TableCell component="th" scope="row">
                                            <Render 
                                                entity={entity}                                
                                                                      
                                                onChange={
                                                    (renderFormState, typeId, entityData, callback) => {handleOnChangeRender(entity.id, renderFormState, typeId, entityData, callback)}
                                                }   
                                            />
                                        </TableCell>                                            
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        )
    }
}