// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { memo } from 'react';
// import { useState, useEffect } from 'react'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {    
    Grid,
    Typography,
    Button
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {   } from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- COMPONENTS -
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import Field from './field/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------
// ----------------------------------------------------------------------

const MemoField = memo(Field)

const Element = (props) => {    
    const{element, parentType, ...otherProps} = props    

    switch (element.type) {
        case "grid": {
            return (
                <Grid {...element.props}>
                    {element.content.map((element) => (
                        <Element 
                            element={element}
                            parentType={"grid"}
                            {...otherProps}
                        />
                    ))}
                </Grid>
            )
        }

        case "typography": {
            return (
                <Typography {...element.props}>
                    {element.content.map((element) => (
                        <Element 
                            element={element}
                            parentType={"typography"}
                            {...otherProps}                            
                        />
                    ))}
                </Typography>
            )
        }

        case "string": {
            return (
                element.value
            )
        }

        case "field": {
            if (parentType == "typography") {
                return (otherProps.entity.data[element.value])
            } else {
                return (
                    <MemoField
                        fieldId={element.value}
                        {...element.props}
                        {...otherProps}                    
                    />
                )
            }
        }

        case "button": {
            return (
                <Button {...element.props}>{element.label}</Button>
            )
        }

        default: {
            return (
                <React.Fragment/>
            )
        }
    }
}

export default Element