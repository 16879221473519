// --------------------------------------------------------- REACT ------
import * as React from 'react'
import { memo } from 'react';

// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI --------
import {
    TextField,
} from '@mui/material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI OTHER --
// ----------------------------------------------------------------------
// --------------------------------------------------------- MUI ICONS --
// import {} from '@mui/icons-material/'
// ----------------------------------------------------------------------
// --------------------------------------------------------- OTHER ------
// ----------------------------------------------------------------------
// --------------------------------------------------------- SIMPLEUI ---
// import {} from './../../../../simpleUI'
// ----------------------------------------------------------------------
// --------------------------------------------------------- LOCAL ------
import String from './string'
import Richtext from './richtext'
// ----------------------------------------------------------------------
// --------------------------------------------------------- CONST ------

export default memo((props) => {
    const handleOnChange = (event) => {        
            props.onChange(event)
    }

    switch (props.type.fields[props.fieldId].subType) {   
        case "string": {
            return (
                <String
                    {...props}
                        
                    onChange={handleOnChange}
                />
            )
        }     

        case "number": {
            return (<React.Fragment></React.Fragment>)
        }     
        
        case "email": {
            return (
                <String
                    {...props}
                        
                    onChange={handleOnChange}
                />
            )
        }

        case "password": {
            return (<React.Fragment></React.Fragment>)
        }     

        case "mask": {
            return (<React.Fragment></React.Fragment>)
        }

        case "multiline": {
            return (<React.Fragment></React.Fragment>)
        }     

        case "richtext": {
            return (
                <Richtext
                    {...props}
                        
                    onChange={handleOnChange}
                />
            )
        }     

        default: {
            return (<React.Fragment></React.Fragment>)
        }    
    }
})